import * as bootstrap from 'bootstrap';
import $ from 'jquery';
//import Swiper from 'swiper/bundle';
import './file-input';
import './helpers';
import './accessibility';

$(function() {
    // Homepage hero banner text alignment on laptops
    const heroBannerContent = $('.app .hero__content');
    const heroBannerSearch = $('.app .hero__search-wrapper .searchform');
    let headerLogoOffsetLeft = $('header .header-content').offset().left;
    let subtractMargin = $('.hero.row').offset().left;

    if(subtractMargin > 0) {
        headerLogoOffsetLeft = headerLogoOffsetLeft - subtractMargin;
    }

    if($(window).width() >= 1024) {
        $(heroBannerContent).css('margin-left', `${headerLogoOffsetLeft > 20 ? headerLogoOffsetLeft : 20}px`);
        $(heroBannerSearch).css('padding-left', `${headerLogoOffsetLeft > 20 ? headerLogoOffsetLeft : 20}px`);
    }

    $(heroBannerSearch).css('display', `block`);

    $(window).resize(function() {
        headerLogoOffsetLeft = $('header .header-content').offset().left;
        let subtractMargin = $('.hero.row').offset().left;
        if(subtractMargin > 0) {
            headerLogoOffsetLeft = headerLogoOffsetLeft - subtractMargin;
        }
        if($(window).width() >= 1024) {
            $(heroBannerContent).css('margin-left', `${headerLogoOffsetLeft > 20 ? headerLogoOffsetLeft : 20}px`);
            $(heroBannerSearch).css('padding-left', `${headerLogoOffsetLeft > 20 ? headerLogoOffsetLeft : 20}px`);
        } else {
            $(heroBannerContent).css('margin-left', '');
            $(heroBannerSearch).css('padding-left', '');
        }
    })

    $('button[data-accessability-font]').click(function() {
        headerLogoOffsetLeft = $('header .header-content').offset().left;
        let subtractMargin = $('.hero.row').offset().left;
        if(subtractMargin > 0) {
            headerLogoOffsetLeft = headerLogoOffsetLeft - subtractMargin;
        }
        if($(window).width() >= 1024) {
            $(heroBannerContent).css('margin-left', `${headerLogoOffsetLeft > 20 ? headerLogoOffsetLeft : 20}px`);
            $(heroBannerSearch).css('padding-left', `${headerLogoOffsetLeft > 20 ? headerLogoOffsetLeft : 20}px`);
        } else {
            $(heroBannerContent).css('margin-left', '');
            $(heroBannerSearch).css('padding-left', '');
        }
    })
})

$(function() {
    $('.header-menu__main-toggle').click(function() {
        $('.header-mobile').toggleClass('active');
        //disable scroll on body
        if ($('.header-mobile').hasClass('active')) {
            $('body').css('overflow', 'hidden');
        } else {
            $('body').css('overflow', 'auto');
        }
    })
    $('.header-mobile .menu-item-has-children').click(function() {
        const currentHeight = $(this).find('.sub-menu').height();
        $(this).toggleClass('active');
        let autoHeight = 0;

        if($(this).find('.sub-menu').hasClass('active')) {
            autoHeight = 0;
        } else {
            $(this).find('.sub-menu').css('height', 'auto');
            autoHeight = $(this).find('.sub-menu').hasClass('active') ? 0 : $(this).find('.sub-menu').height();
        }

        $(this).find('.sub-menu').toggleClass('active');

        $(this).find('.sub-menu').height(currentHeight).animate({height: autoHeight}, 300);
    })
});

$(function() {
    $('[data-bs-hover=tab]').hover(function() {
        const targetElement = $(this).data('bs-target');
        $(this).parent().parent().parent().find('.nav-link').removeClass('active');
        $(this).addClass('active');
        $(targetElement).parent().find('.tab-pane').removeClass('active');
        $(targetElement).addClass('active');
    });
});

let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
})

//$(function() {
//    const swiper = new Swiper('.swiper-container', {
//        slidesPerView: 3,
//        spaceBetween: 30,
//        breakpoints: {
//            0: {
//                slidesPerView: 1.5,
//                spaceBetween: 10
//            },
//            468: {
//                slidesPerView: 2.5,
//                spaceBetween: 15
//            },
//            768: {
//                slidesPerView: 3,
//                spaceBetween: 20
//            },
//            1024: {
//                slidesPerView: 4,
//                spaceBetween: 25
//            },
//            1320: {
//                slidesPerView: 5,
//                spaceBetween: 25
//            }
//        }
//    });
//});