import $ from 'jquery';
$(function() {
    const fileInput = $(".file-input");

    $(fileInput).each(function() {
        const fileInput = $(this).find("input[type='file']");
        const customButton = $(this).find(".file-input-button");
        const fileName = $(this).find(".file-input-filename");

        customButton.click(function () {
            fileInput.click();
        });

        fileInput.change(function () {
            const selectedFile = fileInput[0].files[0];
            if (selectedFile) {
                fileName.text(selectedFile.name);
            } else {
                fileName.text('');
            }
        });
    })
});