import $ from 'jquery';

$(function() {
    const contrast = localStorage.getItem('contrast')
    const fontSize = localStorage.getItem('fontSize')

    //if(fontSize) {
    //    $('html').attr('data-font-size', fontSize)
    //    $(`.font-size-btn[data-accessibility-font="${fontSize}"]`).addClass('active')
    //} else {
    //    $('.font-size-btn[data-accessibility-font="100"]').addClass('active')
    //}
//
    //if(contrast) {
    //    $('html').attr('data-bs-theme', contrast)
    //    $(`.contrast-btn[data-contrast="${contrast}"]`).addClass('active')
    //} else {
    //    $('.contrast-btn[data-contrast="contrast-1"]').addClass('active')
    //}

    $('.accessibility-button').click(function() {
        $('.accessibility-container').toggleClass('active');
    })

    $('.accessibility-button').keydown(function(e) {
        if(e.keyCode == 13) {
            $('.accessibility-container').toggleClass('active');
        }
    })

    $('.contrast-btn').click(function (e) {
        const selectedContrast = $(this).data('contrast')
        $('.contrast-btn').removeClass('active')
        $(this).addClass('active')
        localStorage.setItem('contrast', selectedContrast)
        $('html').attr('data-bs-theme', selectedContrast)
        $.ajax({
            url: `${window.location.origin}/wp-json/accessability/contrast`,
            type: 'POST',
            data: { contrast: selectedContrast },
            success: function(response) {
                console.log('Contrast setting saved:', response);
            }
        });
    })

    $('button[data-accessability-font]').on('click', function(e) {
        const fontPercentage = $(this).data('accessability-font');
        $('html').attr('data-font-size', fontPercentage);
        localStorage.setItem('fontSize', fontPercentage);
        $.ajax({
            url: `${window.location.origin}/wp-json/accessability/font-size`,
            type: 'POST',
            data: { fontSize: fontPercentage },
            success: function(response) {
                console.log('Font size setting saved:', response);
            }
        });
    })

    $('button[data-accessability-reset]').on('click', function(e) {
        $('.contrast-btn').removeClass('active')
        $('.contrast-btn[data-contrast="contrast-1"]').addClass('active')
        localStorage.setItem('contrast', 'contrast-1')
        localStorage.setItem('fontSize', '100')
        $('html').attr('data-bs-theme', 'contrast-1')
        $('html').attr('data-font-size', '100')
        $.ajax({
            url: `${window.location.origin}/wp-json/accessability/contrast`,
            type: 'POST',
            data: { contrast: 'contrast-1' },
            success: function(response) {
                console.log('Contrast setting reset:', response);
            }
        });
        $.ajax({
            url: `${window.location.origin}/wp-json/accessability/font-size`,
            type: 'POST',
            data: { fontSize: '100' },
            success: function(response) {
                console.log('Font size setting reset:', response);
            }
        });
    })
});
